@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'DM Sans, sans-serif';
    font-style: normal;
    font-weight: 400 500 700;
    font-display: optional;
    src: url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

ul, li {list-style-type: none;}

.floating { 
  animation-name: floating; 
  animation-duration: 3s; 
  animation-iteration-count: infinite; 
  animation-timing-function: ease-in-out;
} 
@keyframes floating { 
  0% { transform: translate(0, 0px); } 
  50% { transform: translate(0, 8px); } 
  100% { transform: translate(0, -0px); }  
} 
.floating-4 { 
  animation-name: floating; 
  animation-duration: 4s; 
  animation-iteration-count: infinite; 
  animation-timing-function: ease-in-out;
} 
@keyframes floating-4 { 
  0% { transform: translate(0, 0px); } 
  50% { transform: translate(0, 8px); } 
  100% { transform: translate(0, -0px); }  
}